<template>
  <div class="add-new-configuration">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('configuration.create')"
      :title="$t('configuration.create')"
      @submitPressed="submitPressed"
    />
    <configurations-form
      v-if="configuration"
      :configurationObject="configuration"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ConfigurationsForm from "./components/ConfigurationForm.vue";

export default {
  components: {
    TitleBar,
    ConfigurationsForm,
  },
  data() {
    return {
      configuration: {
          bold: false,
          quantityAble: false,
          price: '',
          name: '',
          description: ''
      },
      action: null,
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
